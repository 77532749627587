import axios from 'axios'

const BASE_URL = document.head.querySelector('base').href + 'api'

axios.create({
    baseURL: BASE_URL
});

axios.interceptors.request.use(config => {
    let token = document.head.querySelector('meta[name="csrf-token"]')
    let ApiToken = document.head.querySelector('meta[name="api-token"]')
    window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

    if (token) {
        config.headers['X-CSRF-TOKEN'] = token.content;
        config.headers['Authorization'] = `Bearer ${ ApiToken.content }`;
    }

    config.url = BASE_URL + '/' + config.url
    return config;
})

axios.interceptors.response.use(
    response => response,
    error => {
        const response = error.response
        let type = 'error'
        let message = ''

        if (response != undefined) {
            if (response.data.message != undefined)
                message = `<strong>${response.data.message}</strong>\n`

            if ([400, 405, 408, 500, 504].includes((response.status))) {
                console.log(response);
                message = `<strong>Exception: ${response.data.exception}</strong>\n`
                message += `${response.data.message}\n`

            } else if (response.status == 422) {
                type = 'warning'
                if (response.request.responseType === 'blob' &&
                    response.data instanceof Blob &&
                    response.data.type && response.data.type.toLowerCase().indexOf('json') != -1) {

                    return new Promise((resolve, reject) => {
                        let reader = new FileReader();
                        reader.readAsText(error.response.data)
                        reader.onload = () => {
                            error.response.data = JSON.parse(reader.result);

                            message = getErrorMessage(error.response)
                            resolve(Promise.reject({ type: type, message: message }));
                        }
                        reader.onerror = () => reject(error)
                    })
                } else {
                    message = getErrorMessage(response)
                }
            } else if (response.status == 401) {
                document.head.querySelector('meta[name="csrf-token"]').content = ''
                document.head.querySelector('meta[name="api-token"]').content = ''
                message = 'Error 401: User tidak ter-autentikasi. '

                alert(message)
                location.replace('/logout')


            } else {
                message = `Terjadi kesalahan`
                console.error('errors', response)
            }
        } else {
            message = 'Network Error'
            console.error('errors', response)
        }

        return Promise.reject({ type: type, message: message })
    }
)

function getErrorMessage(response) {
    let message = ''

    if (response.data.message != undefined) message = `<strong>${response.data.message}</strong>\n`
    if (response.data.errors != undefined) {
        const errors = response.data.errors

        if (typeof errors == 'string') message += `${errors} \n`
        else if (errors instanceof Object)
            Object.keys(errors).forEach(error => {
                if (error != 'file') {
                    if (typeof errors[error] == 'string' && !['warning', 'error'].includes(error)) {
                        message += `${errors[error]} \n`
                    } else {
                        errors[error].forEach(msg => {
                            message += `${msg} \n`
                        })
                    }
                }
            })
        else console.error('errors', response)

    } else {
        if (typeof response.data == 'object') {
            const errors = response.data

            Object.keys(errors).forEach(error => {
                if(error == 'message') return
                if (typeof errors[error] == 'string') {
                    message += `${errors[error]} \n`
                } else {
                    errors[error].forEach(msg => {
                        message += `${msg} \n`
                    })
                }
            })
        } else {
            message = `Error ${response.status}: ${response.statusText}`
            console.error('errors', response)
        }
    }

    if (message == '') message = 'Something went wrong.'
    return message
}

window.axios = axios
export default axios