import Vue from 'vue'
import jQuery from 'jquery'
import axios from './utils/axios'
import alertify from 'alertifyjs'
import moment from 'dayjs'
import dt from 'datatables.net-dt'
import { ColourPicker } from 'vue-colour-picker'
import Select2 from 'v-select2-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

require('flatpickr')
require('popper.js')
require('bootstrap')
require('chart.js')
require('chartjs-plugin-datalabels')

window.Vue = Vue
window.axios = axios
window.$ = window.jQuery = jQuery
window.alertify = alertify
window.moment = moment
window.moment.extend(require('dayjs/plugin/isBetween'))

Vue.component("ColourPicker", ColourPicker)
Vue.component('Select2', Select2)
Vue.component("DatePicker", DatePicker)
