<template>
	<div>
		<template v-if="model == 'one-col'">
			<div class="form-group">
				<div class="row">
					<div :class="(formInline != undefined && formInline == false)? 'col-12' : 'col-lg-4'">
						<label :for="idStart || 'start_date'">{{labelStart || 'Tgl. Mulai'}}</label>
					</div>
					<div :class="(formInline != undefined && formInline == false)? 'col-12' : 'col-lg-8'">
						<div class="input-group">
							<input class="form-control"
								data-id="start_date"
								:data-timer="timer? 1 : 0"
								:data-min="min"
								:data-max="max"
								:name="nameStart"
								type="text" 
								:id="idStart || 'start_date'" 
								:value="startValue"
								:placeholder="timer? 'YYYY-MM-DD HH:II' : 'YYYY-MM-DD'"
							>
							<a class="input-group-append mt-2 p-1" onclick="this.previousElementSibling.click()">
								<i class="fa fa-calendar"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div :class="(formInline != undefined && formInline == false)? 'col-12' : 'col-lg-4'">
						<label :for="idEnd || 'end_date'">{{labelEnd || 'Tgl. Selesai'}}</label>
					</div>
					<div :class="(formInline != undefined && formInline == false)? 'col-12' : 'col-lg-8'">
						<div class="input-group">
							<input class="form-control" 
								data-id="end_date"
								:data-timer="timer? 1 : 0"
								:data-min="min"
								:data-max="max"
								:name="nameEnd"
								type="text" 
								:id="idEnd || 'end_date'" 
								:value="endValue"
								:placeholder="timer? 'YYYY-MM-DD HH:II' : 'YYYY-MM-DD'"
							>
							<a class="input-group-append mt-2 p-1" onclick="this.previousElementSibling.click()">
								<i class="fa fa-calendar"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-else-if="model == 'one-row'">
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group">
						<label :for="idStart || 'start_date'">{{labelStart || 'Tgl. Mulai'}}</label>
						<div class="input-group">
							<input class="form-control"
								data-id="start_date"
								:data-timer="timer? 1 : 0"
								:data-min="min"
								:data-max="max"
								:name="nameStart"
								type="text" 
								:id="idStart || 'start_date'" 
								:value="startValue"
								:placeholder="timer? 'YYYY-MM-DD HH:II' : 'YYYY-MM-DD'"
							>
							<a class="input-group-append mt-2 p-1" onclick="this.previousElementSibling.click()">
								<i class="fa fa-calendar"></i>
							</a>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label :for="idEnd || 'end_date'">{{labelEnd || 'Tgl. Selesai'}}</label>
						<div class="input-group">
							<input class="form-control" 
								data-id="end_date"
								:data-timer="timer? 1 : 0"
								:data-min="min"
								:data-max="max"
								:name="nameEnd"
								type="text" 
								:id="idEnd || 'end_date'" 
								:value="endValue"
								:placeholder="timer? 'YYYY-MM-DD HH:II' : 'YYYY-MM-DD'"
							>
							<a class="input-group-append mt-2 p-1" onclick="this.previousElementSibling.click()">
								<i class="fa fa-calendar"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
require('flatpickr/dist/plugins/confirmDate/confirmDate.css')
import confirmDate from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'
import flatpickr from 'flatpickr'
import moment from 'dayjs'

export default {
	name: 'dateRanges',
	props: {
		model: {type: String, default: 'one-col', required: false}, // one-col, one-row
		formInline: {type: Boolean, default: false, required: false}, 
		timer: {type: Boolean, default: false, required: false}, 
		min: {type: String, default: null, required: false}, 
		max: {type: String, default: null, required: false},  
		idStart: {type: String, default: 'start_date', required: false}, 
		labelStart: {type: String, default: null, required: false}, 
		startValue: {type: String, default: null, required: false}, 
		startRequired: {type: Boolean, default: false, required: false}, 
		nameStart: {type: String, default: 'start_date', required: false}, 
		idEnd: {type: String, default: 'end_date', required: false}, 
		labelEnd: {type: String, default: null, required: false}, 
		endValue: {type: String, default: null, required: false}, 
		endRequired: {type: Boolean, default: false, required: false}, 
		nameEnd: {type: String, default: 'end_date', required: false}, 
	},
	watch:{
		idStart(val){
			this.init()
		}
	},
	methods:{
		init(){
			let $start = document.getElementById(this.idStart)
			let $end = document.getElementById(this.idEnd)
			let useTimer, useMinDate, useMaxDate = false
			let flatPickrOptions = {
				dateFormat: 'Y-m-d',
				plugins: [new confirmDate({
					showAlways:true
				})]
			}

			if(this.startRequired) $start.setAttribute('required', 'required')
			if(this.endRequired) $end.setAttribute('required', 'required')

			useTimer = Boolean(parseInt($start.dataset.timer))
			useMinDate = Boolean($start.dataset.min)
			useMaxDate = Boolean($start.dataset.max)

			if(useTimer === true){
				Object.assign(flatPickrOptions, {
					time_24hr: useTimer,
					enableTime: useTimer,
					dateFormat: 'Y-m-d H:i'
				})
			}

			if(useMinDate === true){
				Object.assign(flatPickrOptions, {
					minDate: $start.dataset.min
				})
			}
			
			if(useMaxDate === true){
				Object.assign(flatPickrOptions, {
					maxDate: $start.dataset.max
				})
			}
			
			flatpickr($end, 
			{...flatPickrOptions, ...{
				minDate: $start.value
			}})
			
			flatpickr($start, 
				{...flatPickrOptions, ...{
					onChange(selected, dateStr, el){
						var $endDate = $end._flatpickr
						var currentEndDate = $endDate.selectedDates

						if(moment(dateStr).isAfter(currentEndDate)){
							var newVal = moment(dateStr).add(1, 'weeks').format(useTimer? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')
							$endDate.setDate(newVal)
						}
						$endDate.set('minDate', dateStr)
						$end.dataset.min = dateStr
						// el.element._flatpickr.close()
					}
				}}
			)
		}
	},
	mounted(){
		this.init()
	}
}
</script>
